@import "base/normalize";

*, *:before, *:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

canvas {
  width: 100vw;
  height: 100vh;
}

#stage {
  position: relative;
  z-index: 1;
}

#noise {
  position: absolute;
  inset: 0;
  z-index: 2;
}
